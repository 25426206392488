import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { NotificationKeyword, TemplateData, TemplateAsset, NotificationType, TemplateDomainData, NotificationEvents, Language, NotificationTemplateContents } from './crud-email-template.model';
import { CrudEmailTemplatesBusiness } from './crud-email-template.business';
import * as DecoupledEditor from '../../../ckeditor5-build-decoupled-document-master/build/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

import { cloneDeep } from 'lodash'
import { TemplateDataServices } from 'src/app/common/dataservices/notification/template-data-service';
import { EventDataServices } from 'src/app/common/dataservices/notification/event.data.service';
import { Events, EventNotification } from '../../notification-configuration/notification-configuration.model';
import { TemplateServiceCommunication } from '../../templates.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { AlertAction, AlertType, ButtonTypes } from 'src/app/common/Models/common.models';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { Product } from 'src/app/common/enums/shared-enums';
import { SorTypeEnum } from 'src/app/common/shared/shared/enums/enums';
import { DialogOverviewExampleDialog } from 'src/app/common/shared/shared/dialog-popup/dialogPopup-componenet';
import { MatDialog } from '@angular/material/dialog';
import { HtmlPopupComponent } from 'src/app/common/shared/shared/html-popup/html-popup.component';
import { CkeditorPopupComponent } from 'src/app/common/shared/shared/ckeditor-popup/ckeditor-popup.component';
import { DmConfig } from '../../../Models/common.models';
import { CommonDataAwaiters } from 'src/app/common/shared/events/awaiters/common.data.awaiters';
import juice from 'juice';


@Component({
  selector: 'app-crud-email-template',
  templateUrl: './crud-email-template.component.html',
  styleUrls: ['./crud-email-template.component.scss'],
  providers: [CrudEmailTemplatesBusiness, TemplateDataServices, EventDataServices, TemplateServiceCommunication


  ],
  encapsulation: ViewEncapsulation.None
})
export class CrudEmailTemplateComponent implements OnInit {

  @Input() editableObj;
  @Input() fromBulkNotify;
  @Output() emittedEmaildata: EventEmitter<any> = new EventEmitter();  
  @Input() showTableRecord;
  @Input() isCopyInput;
  @Input() isViewOnly;
  isShowCopy: boolean = false;
  isGroupingenabled = true;
  ordertype = SorTypeEnum.asc;
  groupBy = 'groupId';

  HeaderLabel: string;
  saveUpdLabel: string;
  productId: number;
  searchText: string = '';
  Entire_keywords: NotificationKeyword[];
  filteredkeywords: NotificationKeyword[];
  editableData: TemplateDomainData;
  templatedomaindata: TemplateDomainData;
  isTemplateInUse: boolean = false;
  EnablesaveUpdBtn: boolean = false;
  CrudEmmailTempForm: UntypedFormGroup;
  crudemailtemplateCaptions: any;
  events: Events[];
  newlyCreatedTemplateId: number = 0;
  templateCodeMaxlength: number = 12;
  pmsTemplateCodeMaxlength: number = 15;
  message;
  myContent: any;
  captions: any;
  selectedlanguage: any;
  enableMultiLanguage: boolean = false;
  SelectedLanguagueID: number = 1;
  Editor = DecoupledEditor;
  Config = {
    toolbar: [
      'bold', 'italic', 'underline', 'strikethrough', 'fontfamily', 'fontsize',
      'fontcolor', 'fontbackgroundcolor', 'heading', 'alignment', 'numberedList',
      'bulletedList', 'todoList', 'outdent', 'indent', 'link', 'insertTable', 'imageUpload',
      'undo', 'redo', 'htmlEmbed'
    ],
    fontColor: {
      colors: this.commonUtils.getColors()
    },
    fontBackgroundColor: {
      colors: this.commonUtils.getColors()
    },
    fontSize: {
      options: this.commonUtils.getFontSize(),
      supportAllValues: true
    },
    table: {
      tableProperties: {
        borderColors: this.commonUtils.getColors(),
        backgroundColors: this.commonUtils.getColors()
      },
      tableCellProperties: {
        borderColors:this.commonUtils.getColors(),
        backgroundColors: this.commonUtils.getColors()
      }
    },
    extraPlugins: [],//Base64UploaderPlugin
    placeholder: 'Type here',
    toolbarLocation: ['bottom'],
    fillEmptyBlocks: false,
    basicEntities: false
  };
  @ViewChild('ComposeEmailTemp') ComposeEmailTemp: CKEditorComponent;
  currentEditor: any
  $destroyed: ReplaySubject<any> = new ReplaySubject(1);
  isDefaultEventObj: any;
  floatLabel: string;
  floatLabelNever: string;
  dmConfig: DmConfig;
  isIntakeForm: boolean;
  languages: Language[];
  langCode: string;
  constructor(private fb: UntypedFormBuilder, public localization: Localization, private _CrudEmailTemplatesBusiness: CrudEmailTemplatesBusiness,
    private _eventBusiness: EventDataServices, private templateServiceCommunication: TemplateServiceCommunication, private commonUtils: CommonUtilities, public dialog: MatDialog) {
    this.crudemailtemplateCaptions = this.localization.captions.settings.utilities;
    this.Config.placeholder = this.crudemailtemplateCaptions.typehere;
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  async ngOnInit() {
    this.templateServiceCommunication.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';

        }
        else {
          loadingContainer.style.display = 'none';

        }
      }
    });
    this.generateEmailFrom();

    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.templateCodeMaxlength = this.productId == Product.PMS ? this.pmsTemplateCodeMaxlength : this.templateCodeMaxlength;
    this.enableMultiLanguage = this.productId == Product.PMS;
    this.templateServiceCommunication.loaderEnable.next(true);
    this.languages = await this._CrudEmailTemplatesBusiness.getLanguages();
    this.langCode = String(this.localization.GetPropertyInfo('Language'));
    this.patchDefaultLanguage()
    this.isShowCopy = this.isCopyInput?.isCopy;
    if(this.productId!=Product.SALESANDCATERING){
    this.dmConfig = await this._CrudEmailTemplatesBusiness.getDmconfigSession();
    }
    this.isIntakeForm = await CommonDataAwaiters.GetIntakeDetails();
    await this.GetEvents();
    if (this.editableObj) {
      this.HeaderLabel = !this.isShowCopy ? this.crudemailtemplateCaptions.editEmailTemplate : this.crudemailtemplateCaptions.newEmailTemplate;
      this.saveUpdLabel = !this.isShowCopy ? this.crudemailtemplateCaptions.update : this.crudemailtemplateCaptions.save;
      await this.GetTemplateData(this.editableObj.id, this.SelectedLanguagueID);
      if (this.enableMultiLanguage && this.editableData?.templateData != null && this.editableData?.templateData?.notificationTemplateContents != null && this.editableData?.templateData?.notificationTemplateContents.length > 0) {
        this.editableData.templateData.notificationTemplateContents.forEach(x => {
          if (x.languageId != 1)
            this.pushlanguages(x.languageId);
          if (this.enableMultiLanguage) this.languageCascading();
        });
      }
      this.CrudEmmailTempForm.controls.templateCode.disable();
      if(this.isTemplateInUse)
      {
        this.CrudEmmailTempForm.controls.templateName.disable();
      }
    }
    else {
      this.HeaderLabel = this.crudemailtemplateCaptions.newEmailTemplate;
      this.saveUpdLabel = this.crudemailtemplateCaptions.save;
      await this.GetMasterData();
      if (this.enableMultiLanguage) this.setAllLanguagesAsDisabled();
    }

    if (this.isShowCopy) {
      this.CrudEmmailTempForm.controls.templateCode.enable();
      this.message = this.localization.replacePlaceholders(this.crudemailtemplateCaptions.lbl_templatecopyAlertMessage, ['className'],
        [this.isCopyInput.eventName]);
    }
    this.disableEvents();
    this.CrudEmmailTempForm.statusChanges.subscribe(x => {
      this.EnablesaveUpdBtn = this.CrudEmmailTempForm.dirty && this.CrudEmmailTempForm.valid;
    });
    if(this.isViewOnly){
      this.CrudEmmailTempForm.disable();
      this.CrudEmmailTempForm.controls.isActive.disable();
      this.CrudEmmailTempForm.controls.isDefault.disable();
    }
  }

  async GetEvents() {
    this.events = await this._eventBusiness.getAllEventsByProductId(this.productId);
    this.events.forEach(res => res.disabled = false);
    this.events = !this.isIntakeForm ? this.events.filter(event => event.id != EventNotification.IntakeForm) : this.events;
    let isGdprEnabled = JSON.parse(sessionStorage.getItem('isGdprEnabled'));
    if(!isGdprEnabled) this.events = this.events.filter(event => event.id != EventNotification.GolfGuestDataRequest && event.id != EventNotification.SPAGuestDataRequest); 

    if(this.productId == Product.ACCOUNTING)
    {
      let propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
      let showAddOn = propConfig?.ShowAddOn;
      if (!showAddOn || showAddOn == null || showAddOn?.toString().toLowerCase() == 'false') {
        this.events = this.events.filter(event => event.id != EventNotification.PurchaseRequisitionApprovalNotification)
      }
    }

    if (this.productId == Product.PMS) {
      this.events = this.events.filter(event => event.id != EventNotification.Anniversary
        && event.id != EventNotification.Birthday
        && event.id != EventNotification.Consents
        && event.id != EventNotification.EventsGroups
        && event.id != EventNotification.EventsReminder
        && event.id != EventNotification.GDPROptInGuest
        && event.id != EventNotification.Groups
        && event.id != EventNotification.GuestStayFeedback
        && event.id != EventNotification.Invoices
        && event.id != EventNotification.NoShow
        && event.id != EventNotification.PreCheckIn
        && event.id != EventNotification.Reactivation
        && event.id != EventNotification.RMSEvent
        && event.id != EventNotification.VIPArrivals
        && event.id != EventNotification.WeddingsGroups
        && event.id != EventNotification.WelcomeMessage
        && event.id !=EventNotification.AccountCreated
        && event.id !=EventNotification.BookingCreated        
        && event.id != EventNotification.OTABookingConfirmation
        && event.id != EventNotification.OTABookingModification
        && event.id != EventNotification.OTABookingCancellation);
    }
  }

  async GetMasterData() {
    let listOrder = await this._CrudEmailTemplatesBusiness.getMaxListOrder(NotificationType.EMAIL);
    if (this.events && this.events.length > 0) {      
      if(this.fromBulkNotify){
        let eventarr: number[] = [];
      eventarr.push(this.events.find(x=> x.id == EventNotification.General).id);
      await this.getTemplateKeywords(eventarr);
      this.CrudEmmailTempForm.controls.eventId.setValue(eventarr);
      this.CrudEmmailTempForm.controls.listOrder.setValue(listOrder + 1);
      }
      else{
        let eventarray: number[] = [];
        if(this.editableData != null){
          eventarray.push(this.events[0].id);
        }
      await this.getTemplateKeywords(eventarray);
      this.CrudEmmailTempForm.controls.eventId.setValue(eventarray);
      this.CrudEmmailTempForm.controls.listOrder.setValue(listOrder + 1);
      }      
    }
    this.templateServiceCommunication.loaderEnable.next(false);
  }

  async GetTemplateData(id: number, languageid: number) {
    this.editableData = await this._CrudEmailTemplatesBusiness.getTemplateDetailsById(id, languageid);
    await this.getTemplateKeywords(this.editableData.eventId);
    await this.patchValues();
    this.templateServiceCommunication.loaderEnable.next(false);
  }


  generateEmailFrom() {
    this.CrudEmmailTempForm = this.fb.group({
      eventId: ['', Validators.required],
      templateCode: ['', Validators.required],
      templateName: ['', Validators.required],
      listOrder: ['', Validators.required],
      htmlContent: '',
      isActive: true,
      isDefault: false,
      templateId: null,
      notificationtemplateId: 0,
      building: '',
      editorHtml: this.fb.array([])
    });

  }

  patchDefaultLanguage() {
    let lang = this.languages.find(x => x.id == 1);
    let frmArray = this.CrudEmmailTempForm.get('editorHtml') as UntypedFormArray;
    let data = this.fb.group({
      tabTitle: lang?.viewValue,
      htmlContents: '',
      languageSelector: lang?.id
    })

    frmArray.push(data);
    this.languageCascading();
  }

  pushlanguages(languagueid: number) {
    let lang = this.languages.find(x => x.id == languagueid);
    let frmArray = this.CrudEmmailTempForm.get('editorHtml') as UntypedFormArray;
    let data = this.fb.group({
      tabTitle: lang?.viewValue,
      htmlContents: '',
      languageSelector: lang?.id
    })

    frmArray.push(data);

  }
  async patchValues() {
    let templatedata = this.editableData.templateData;
    let data = {
      eventId: this.editableData.eventId,
      templateCode: templatedata.templateCode,
      templateName: templatedata.templateName,
      listOrder: templatedata.listOrder,
      htmlContent: templatedata.htmlContent,
      isActive: templatedata.isActive,
      isDefault: templatedata.isDefault,
      templateId: templatedata.templateId,
      notificationtemplateId: templatedata.notificationTemplateId
    }
    if (this.isShowCopy) {
      let nextlistOrder = await this._CrudEmailTemplatesBusiness.getMaxListOrder(NotificationType.EMAIL);
      data.listOrder = nextlistOrder + 1;
      data.templateCode = '';
      data.notificationtemplateId = 0;
    }
    this.isTemplateInUse = templatedata.isTemplateInUse;
    this.CrudEmmailTempForm.patchValue(data);
    if (this.editableData.templateData.notificationTemplateContents != null && this.editableData.templateData.notificationTemplateContents.length > 0) {

      let content = this.editableData.templateData.notificationTemplateContents.find(x => x.languageId == this.SelectedLanguagueID);
      let lang = this.languages.find(x => x.id == this.SelectedLanguagueID);
      let frmArray = this.CrudEmmailTempForm.get('editorHtml') as UntypedFormArray;
      let index = frmArray.value.findIndex(x => x.languageSelector == this.SelectedLanguagueID);
      let notificationcontent = this.fb.group({
        tabTitle: lang?.viewValue,
        htmlContents: templatedata.htmlContent,
        languageSelector: lang?.id
      })

      index < 0 ? frmArray.push(notificationcontent) : frmArray.controls[index].patchValue(notificationcontent.value);

    }

  }

  async showConfirmationSuccessCallback(res) {
    await this.SaveTemplate();
  }

  async showConfirmationErrorCallback(res) {
    this.CrudEmmailTempForm.value['isDefault'] = false;
    await this.SaveTemplate();
  }

  async SaveTemplate() {
    this.openVal();
    let id: number = 0;
    try {
      this.templateServiceCommunication.loaderEnable.next(true);
      this.EnablesaveUpdBtn = false;
      this.templatedomaindata = undefined;
      const formvalue = this.CrudEmmailTempForm.getRawValue();
      this.UpdateTemplateDataObject(formvalue);
      this.CrudEmmailTempForm.markAsPristine();
      if (this.templatedomaindata) {
        id = await this._CrudEmailTemplatesBusiness.CreateorUpdateTemplate(this.templatedomaindata);
        this.newlyCreatedTemplateId = id;
        if(!this.fromBulkNotify)
          this.GetTemplateData(id, this.SelectedLanguagueID);
      }
      this.templateServiceCommunication.loaderEnable.next(false);
      this.promptForUnSavedChanges();
    } catch (err) {
      id = this.templatedomaindata && this.templatedomaindata.templateData.notificationTemplateId > 0 ? this.templatedomaindata.templateData.notificationTemplateId : id;
      if (err && err.error && err.error.errorCode == '11106' && id > 0) {
        this.GetTemplateData(id, this.SelectedLanguagueID);
      }
    }
    finally {
      this.templateServiceCommunication.loaderEnable.next(false);
    }
  }

  async getTemplateKeywords(selecAct: number[]) {
    this.Entire_keywords = cloneDeep(await this._CrudEmailTemplatesBusiness.getNotificationKeywords(selecAct, this.productId));
    this.filteredkeywords = this.Entire_keywords;
    if (!this.dmConfig?.dmEformsConfig?.enableEforms) {
      this.events = this.events.filter(event => event.id != 75);
      this.filteredkeywords = this.Entire_keywords.filter(keyword => keyword.groupId != 15);
      this.Entire_keywords = this.filteredkeywords;
    }
  }
  promptForUnSavedChanges() {
    if (this.showTableRecord) {
      if (this.CrudEmmailTempForm.dirty) {
        this.commonUtils.showCommonAlert(this.crudemailtemplateCaptions.warn_datalost, AlertType.Warning, ButtonTypes.YesNo, (res) => {
          if (res === AlertAction.YES) {
            this.emittedEmaildata.emit({ closeCrudWindow: true, newlyCreatedTemplateId : this.newlyCreatedTemplateId });
          }
          else {
            return;
          }
        });
      }
      else {
        this.emittedEmaildata.emit({ closeCrudWindow: true , newlyCreatedTemplateId : this.newlyCreatedTemplateId  });
      }
    }
    else {
      this.emittedEmaildata.emit({ closeCrudWindow: true, newlyCreatedTemplateId : this.newlyCreatedTemplateId  });
    }
  }
  async save() {
    let isDefault = this.CrudEmmailTempForm.value['isDefault'];
    let isTagValid = true;
    if (this.CrudEmmailTempForm.value) {
      const htmlContent = this.CrudEmmailTempForm.value['htmlContent'];
      let openTags = htmlContent.match(/{{{(.*?)/g);
      let closeTags = htmlContent.match(/(.*?)}}}/g);
      let openTagsCount = (openTags && openTags.length) ? openTags.length : 0;
      let closeTagsCount = (closeTags && closeTags.length) ? closeTags.length : 0;
      isTagValid = openTagsCount == closeTagsCount;
      let tags = htmlContent.match(/{{{(.*?)}}}/g);

      if (tags && tags.length && isTagValid) {
        for (let i = 0; i < tags.length; i++) {
          const str = tags[i].substring(3, tags[i].length - 3);
          if (str.includes('{{{')) {
            isTagValid = false;
            break;
          }
        }
      }
    }  
    if(isTagValid)
    {
      if (isDefault) {
        this.templateServiceCommunication.loaderEnable.next(true);
        let result = await this._CrudEmailTemplatesBusiness.DefaultTemplateExists(this.CrudEmmailTempForm.value['eventId'], this.CrudEmmailTempForm.value['notificationtemplateId'], NotificationType.EMAIL);
        if (result) {
          this.templateServiceCommunication.loaderEnable.next(false);
          this.templateServiceCommunication.showConfirmationPopup(this.showConfirmationSuccessCallback.bind(this), this.showConfirmationErrorCallback.bind(this));
        } else {
          this.templateServiceCommunication.loaderEnable.next(false);
          await this.SaveTemplate();
        }
      }
      else {
        await this.SaveTemplate();
      }  
    }
    else{
      this.commonUtils.showAlert(this.captions.lbl_invalidTag, AlertType.Info);          
    } 
    
  }
  UpdateTemplateDataObject(formData: UntypedFormGroup) {
    this.AddTemplateDataIfDirty(formData);
  }


  AddTemplateDataIfDirty(formData: UntypedFormGroup) {
    if (this.CrudEmmailTempForm.dirty) {
      const templateCode = formData['templateCode'];
      const templateNameValue = formData['templateName'];
      let formvalue = this.CrudEmmailTempForm.get('editorHtml').value;
      let index = formvalue.findIndex(x => x.languageSelector == this.SelectedLanguagueID);
      let templateid = ""
      const templateContentValue = this.enableMultiLanguage ? this.CrudEmmailTempForm.get('editorHtml').value[index] : formData['htmlContent'];
      if (this.editableData?.templateData?.notificationTemplateContents != null && this.editableData?.templateData?.notificationTemplateContents.length > 0 && !this.isShowCopy) {
        let notificationcontent = this.editableData.templateData.notificationTemplateContents.find(x => x.languageId == this.SelectedLanguagueID);
        if (notificationcontent) templateid = notificationcontent.templateId.toString();
      }
      const ImageData = this.getImageUrlFromHtml(templateContentValue, templateCode + '_', templateid);
      let modifiedHTMLData = juice.inlineContent(this.commonUtils.getCkeditorImgWidth(ImageData.replacedImageSrcURL),this.commonUtils.getCssStringForCkEditor());
      const templateData: TemplateData = {
        templateName: templateNameValue,
        templateCode: templateCode,
        templateType: NotificationType.EMAIL,
        isDefault: formData['isDefault'],
        templateAsset: ImageData.templateAsset,
        htmlContent: modifiedHTMLData,
        templateId: formData['templateId'],
        isActive: formData['isActive'],
        listOrder: formData['listOrder'],
        notificationTemplateId: formData['notificationtemplateId'],
        isTemplateInUse: false,
        notificationTemplateContents: ImageData.notificationdata
      };
      let domainData: TemplateDomainData = {
        eventId: formData['eventId'],
        templateData: templateData
      }
      this.templatedomaindata = domainData;
    }

  }

  getImageUrlFromHtml(notificationcontent, imageNamePrefix, templateid) {
    const parser = new DOMParser();
    const templateAsset: TemplateAsset[] = [];
    const notificationdata: NotificationTemplateContents[] = [];
    if (notificationcontent != null) {
      const templatecontent: NotificationTemplateContents = {
        languageId: this.enableMultiLanguage ? notificationcontent.languageSelector : 1,
        notificationTemplateId: this.CrudEmmailTempForm.value['notificationtemplateId'],
        templateId: templateid ? templateid : this.CrudEmmailTempForm.value['templateId'],
      }
      notificationdata.push(templatecontent);
      var htmlcontent = notificationcontent.htmlContents ? notificationcontent.htmlContents : notificationcontent;
      const document = parser.parseFromString(htmlcontent, 'text/html');
      const imageData = document.getElementsByTagName('img');

      for (let i = 0; i < imageData.length; i++) {
        const imageBase64Data = this.getBase64Data(imageData[i].src);

        if (imageBase64Data) {
          const imageUniqueName = imageNamePrefix + this.commonUtils.generateGUID();
          templateAsset.push(
            {
              contentType: this.getImageType(imageData[i].src),
              fileName: imageUniqueName,
              keyName: imageUniqueName,
              imageData: imageBase64Data
            });
          imageData[i].src = imageUniqueName;
        }
      }
      const replacedImageSrcURL = document.getElementsByTagName('body')[0].innerHTML;
      return { templateAsset, replacedImageSrcURL, notificationdata };
    }

  }

  getBase64Data(url: string) {
    return url.split(',')[1];
  }

  getImageType(url: string) {
    return url.substring(
      url.lastIndexOf(':') + 1,
      url.lastIndexOf(';')
    );
  }
  enteredSrchText() {
    let val = this.searchText.toLowerCase();
    this.filteredkeywords = this.Entire_keywords.filter(x => {
      return (x.name.toLowerCase().indexOf(val) != -1);
    })
  }
  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    this.currentEditor = this.ComposeEmailTemp;
  }
  elementClick(arg) {
    const appendData = '{{{' + arg + '}}}';
    const selection = this.currentEditor.editorInstance.model.document.selection;
    const range = selection.getFirstRange();
    this.currentEditor.editorInstance.model.change(writer => {
      writer.insert(appendData, range.start);
    });
  }

  onChange(currentEditorInstance) {
    this.currentEditor = currentEditorInstance;
  }
  actionChanged(eve) {
    let eventSelec = eve.value;
    this.disableEvents();
    this.getTemplateKeywords(eventSelec);
  }

  disableEvents() {
    const isIntakeFormSelected = this.CrudEmmailTempForm.value.eventId.includes(NotificationEvents.IntakeForm);
    const eventId = this.CrudEmmailTempForm.value.eventId;
    const isEformSelected = eventId.includes(NotificationEvents.Eform) || eventId.includes(NotificationEvents.EFormGolf);
    const uniqueEvents: NotificationEvents[] = [NotificationEvents.IntakeForm, NotificationEvents.Eform, NotificationEvents.EFormGolf]
    this.events.forEach(res => {
      if (isIntakeFormSelected) {
        if (res.id !== NotificationEvents.IntakeForm) {
          res.disabled = true;
        } else {
          res.disabled = false;
        }
      } else if (isEformSelected) {
        if (res.id !== NotificationEvents.Eform && res.id !== NotificationEvents.EFormGolf) {
          res.disabled = true;
        } else {
          res.disabled = false;
        }
      }
      else {
        if (this.CrudEmmailTempForm.controls.eventId.value.length > 0) {
          if (uniqueEvents.includes(res.id)) {
            res.disabled = true;
          } else {
            res.disabled = false;
          }
        } else {
          res.disabled = false;
        }
      }
    });
  }

  openClose(e, obj) {
    this.filteredkeywords.forEach((x, i) => {
      if (obj.groupId == x.groupId) {
        x.isExpanded = !x.isExpanded
      }
    })
  }

  enteredgrpSrchText() {
    let searchValue = this.searchText.toLowerCase();
    let tempArray = [];
    this.Entire_keywords.forEach(searchKey => {
      const result = searchKey.name.toLowerCase().indexOf(searchValue) > -1
      if (result) {
        if (searchValue == "") {
          searchKey.isExpanded = false;
        } else {
          searchKey.isExpanded = true;
        }
        tempArray.push(searchKey);
      }
    })
    this.filteredkeywords = tempArray;
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
    if (!this.showTableRecord) {
      this.promptForUnSavedChanges();
    }
  }
  showHtmlContent() {
    let modifyHTML = juice.inlineContent(this.commonUtils.getCkeditorImgWidth(this.CrudEmmailTempForm.get('htmlContent')?.value),this.commonUtils.getCssStringForCkEditor());
    const dialogRef = this.dialog.open(HtmlPopupComponent, {
      height: '90%',
      width: '90%',
      data: { headername: this.crudemailtemplateCaptions.composed, datarecord: modifyHTML, Type: "HTML" },
      panelClass: 'ag_dialog--lg',
      disableClose: true,
      hasBackdrop: true
    });
  }

  openVal() {
  }

  selectedIndexEmit(event) {
    this.selectedlanguage = event;
    this.SelectedLanguagueID = this.selectedlanguage.languageSelector;
    if (this.editableData?.templateData != null && this.editableData?.templateData?.notificationTemplateContents != null && this.editableData?.templateData?.notificationTemplateContents.length > 0) {
      let content = this.editableData.templateData.notificationTemplateContents.find(x => x.languageId == this.selectedlanguage.languageSelector);
      if (content != undefined && content != null && content.notificationTemplateId > 0) {
        this.GetTemplateData(this.editableObj.id, content.languageId);
      }
    }
  }

  async DeleteNotificationTemplateContent(event) {
    if (event.languageSelector > 0) {
      if (this.editableData?.templateData != null && this.editableData?.templateData?.notificationTemplateContents != null && this.editableData?.templateData?.notificationTemplateContents.length > 0) {
        let content = this.editableData.templateData.notificationTemplateContents.find(x => x.languageId == this.SelectedLanguagueID);
        let templatecontent = await this._CrudEmailTemplatesBusiness.DeleteTemplateNotificationTemplateContent(content.notificationTemplateId, content.languageId);
        this.editableData.templateData.notificationTemplateContents = templatecontent.notificationTemplateContents;
      }

      let frmArray = this.CrudEmmailTempForm.get('editorHtml') as UntypedFormArray;
      let index = frmArray.value.findIndex(x => x.languageSelector == event.languageSelector);
      frmArray.controls[index].get('htmlContents').setValue('')
      frmArray.removeAt(index);

      if (this.CrudEmmailTempForm.get('editorHtml').value.length == 0) {
        this.patchDefaultLanguage();
      }
    }
  }

  languageCascading() {
    let frmArray = this.CrudEmmailTempForm.get('editorHtml') as UntypedFormArray;
    frmArray.controls.forEach(x => {
      this.languages.forEach(y => {
        if (x['controls']['tabTitle'].value == y.viewValue) {
          y.isDisabled = true;
        }
      });
    })

  }

  setAllLanguagesAsDisabled() {
    if (this.languages && this.languages.length > 0) {
      this.languages.forEach(y => {
        y.isDisabled = true;
      });
    }
  }

}
