import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators, UntypedFormControl } from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant';
import { CreditCardConfigurationService } from './credit-card.service';
import { GatewayConfiguration, GatewayValues, UI } from '../../shared/service/payment/payment-model';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { CreditCardIssuerType, GatewayConfigurationType, MAXLENGTH } from '../../shared/globalsContant';
import { EmptyValueValidator } from '../../shared/Validators/EmptyValueValidator';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { AgFieldConfig } from 'src/app/common/Models/ag-models';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { CardInputMethod, SurchargeConfiguration, SurchargeConfigurationAPI, TipConfig, TipConfigAPI } from '../../shared/service/payment/payment-business.model';
import { SurchargeConfigurationService } from '../data-service/surcharge-configuration.data.service';
import { PaymentMethods } from '../../shared/business/shared.modals';
import { Guid } from 'guid-typescript';
import { TipConfigurationService } from '../data-service/tip-configuration.data.service';
@Component({
  selector: 'app-credit-cards',
  templateUrl: './credit-cards.component.html',
  styleUrls: ['./credit-cards.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CreditCardConfigurationService]
})
export class CreditCardsComponent implements OnInit {
  captions: any = {};
  creditcardInfo: UntypedFormGroup;
  GatewayPairs: any = [];
  cardInputMethods: { id: number; value: CardInputMethod; viewValue: string; }[];
  preferredCardInputs: { id: number; value: CardInputMethod; description: string; }[] = [];
  config: GatewayConfiguration;
  isUserAuthorized: boolean = true;
  isViewOnly: boolean = false;
  isUpdateEnabled: boolean = false;
  noOfSVCReceiptsInput: AgFieldConfig;
  batchWaitTimeInput: AgFieldConfig;
  pbfeNetbDelayInput: AgFieldConfig;
  ccOverAuthThresholdInput: AgFieldConfig;
  purgeStayCCDaysInput: AgFieldConfig;
  purgeArchivedCCDaysInput: AgFieldConfig;
  purgeGuestCCDaysInput: AgFieldConfig;
  purgeBookingCCDaysInput: AgFieldConfig;
  purgePostingCCDaysInput: AgFieldConfig;
  featureEnabled: boolean = true;
  functionalities: { [key: string]: boolean } = {};
  showOtherCreditCardToggles = false;
  displayCardInputMethod = false;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  SurchargeLast: number = 0;
  maxPercentage: number = 100;
  hideInAccounting: boolean = false;
  get IsInvalidSurchargeConfig(): boolean {
    return this.surchargeConfig.filter(x => x.isActive && x.isValid && (x.issuerName.length == 0 || (x.isPercentage ? (x.value > 100 || x.value == 0) : x.value == 0))).length > 0;
  }
  get IsBothCardInputsSelected() {
    return (this.creditcardInfo && this.creditcardInfo.controls
      && this.creditcardInfo.controls.cardInputMethod.value == CardInputMethod.All)
  }
  setting: UI.CreditCardConfiguration;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  surchargeConfigCaption: any;
  surchargeConfig: SurchargeConfiguration[] = [];
  decimalMaxLength: number = 2;
  propertyDecimalConfig: number = 2;
  propDigits: number = 2;
  selectAll = false;
  surchargeConfigUpdated: boolean = false;
  floatLabel: string;
  SurchargeConfiguration: SurchargeConfigurationAPI[] = [];
  allowTipConfig = false;
  tipIsPercentage = true;
  tipConfig: TipConfigAPI;
  tipConfigForm: UntypedFormGroup;
  tipConfigCaption;
  presetValMaxLength = 3;

  get showSurchargeConfig() {
    return this.surchargeConfigService?.IsSurchargesEnabled;
  }
  get overAllCBTooltipMsg() {
    return this.surchargeConfig?.every(x => x.isActive) ? this.surchargeConfigCaption?.DeSelectAll : this.surchargeConfigCaption?.SelectAll
  }
  get enableTip() { return this.tipConfigForm.get('enableTip')?.value; }
  get tipValuePlaceHolder() { return `${this.captions.Value} (${this.tipIsPercentage ? '%' : this.localization.currencySymbol})` }

  constructor(private localization: RetailLocalization
    , private fb: UntypedFormBuilder
    , private creditCardConfigurationService: CreditCardConfigurationService
    , private breakpoint: BreakPointAccess
    , private utilities: RetailUtilities
    , private func: RetailFunctionalityBusiness
    , private surchargeConfigService: SurchargeConfigurationService
    , private tipConfigService: TipConfigurationService
  ) {
    this.floatLabel = this.localization.setFloatLabel;
    this.tipConfigCaption = this.localization.captions.setting.TipConfig;
  }
  async ngOnInit() {
    this.captions = this.localization.captions.setting;
    this.surchargeConfigCaption = this.captions.SurchargeConfig;
    await this.surchargeConfigService.GetSurchargeSwitchConfig();
    this.cardInputMethods = [{
      id: 1,
      value: CardInputMethod.All,
      viewValue: this.captions.bothSwipeInsertTapOrKeyIn
    },
    {
      id: 2,
      value: CardInputMethod.KeyIn,
      viewValue: this.captions.keyIn
    },
    {
      id: 3,
      value: CardInputMethod.SwipeInsertTap,
      viewValue: this.captions.swipeInsertTap
    }];
    this.preferredCardInputs = [
      { id: 1, value: CardInputMethod.None, description: "" },
      { id: 2, value: CardInputMethod.SwipeInsertTap, description: this.captions.swipeInsertTap },
      { id: 3, value: CardInputMethod.KeyIn, description: this.captions.keyIn }
    ];
    this.creditcardInfo = this.fb.group({
      id: 0,
      enableActivateInterface: false,
      rGuestPayAgentURL: '',
      rGuestPayGatewayID: '',
      GatewayPairs: this.fb.array([this.createGatewayPairItem(0, '', '')]),
      enablePartialPayment: false,
      enableActivateAVS: false,
      restrictCreditToGuest: false,
      restrictPartialRefund: false,
      enableCCTracking: false,
      enableRequestCardSwipe: false,
      enablePrintCCAuthorization: false,
      automaticallyDeferCCAdvanceDepositPost: false,
      alwaysRequestSwipeAtCheckIn: false,
      enableHouseNumber: false,
      enableChipPinTest: false,
      enableChipAndPin: false,
      skipDecreaseAuth: false,
      incrementalAuthWhenNotSupported: false,
      gatewayId: 0,
      defaultConfigValue: '',
      enableCCAuthLimit: '',
      showCreditCardExpDate: false,
      voidAuthAfterCO: false,
      SET_PARTIAL_PAYMENT: false,
      SET_ACTIVATEAVS: false,
      cardInputMethod: CardInputMethod.All,
      preferredCardEntryMode: CardInputMethod.None
    });

    this.tipConfigForm = this.fb.group({
      id: 0,
      enableTip: false,
      isPercentage: true,
      tipPresetValues: this.fb.array([this.createPresetValues()])
    });

    this.validateBreakPoint();
    this.utilities.ToggleLoader(true,this.captions.lbl_processing);
    this.functionalities = await this.func.getRetailFunctionality()
    this.propDigits = parseInt(sessionStorage.getItem('noOfDecimalDigits'));
    let sessionValue = this.utilities.GetPropertyInfo("MaxDecimalPlaces");
    if (Number(sessionValue) >= 0) {
      this.propertyDecimalConfig = Number(sessionValue);
    }
    if (this.functionalities.ShowCreditCardDefaults) {
      this.creditcardInfo.addControl('noOfSvcReceipts', new UntypedFormControl(''));
      this.creditcardInfo.addControl('batchWaitTimeSec', new UntypedFormControl(''));
      this.creditcardInfo.addControl('pbfeNetpfDelay', new UntypedFormControl(''));
      this.creditcardInfo.addControl('setCCOverAuthAdjustThreshold', new UntypedFormControl(''));
      this.creditcardInfo.addControl('activateAllPABPRecommendation', new UntypedFormControl(false));
      this.creditcardInfo.addControl('purgeStayCCDaysAfterCO', new UntypedFormControl(''));
      this.creditcardInfo.addControl('purgeArchivedCCDaysAfterCO', new UntypedFormControl(''));
      this.creditcardInfo.addControl('purgeGuestCCDaysAfterCO', new UntypedFormControl(''));
      this.creditcardInfo.addControl('purgeBookingCCDaysAfterCO', new UntypedFormControl(''));
      this.creditcardInfo.addControl('purgePostingCCDaysAfterCO', new UntypedFormControl(''));
      this.creditcardInfo.addControl('skipCCFauxNoPurgeDuringZOut', new UntypedFormControl(false));
    }
    this.showOtherCreditCardToggles = this.functionalities.ShowOtherTogglesInCreditCards ? this.functionalities.ShowOtherTogglesInCreditCards : false;
    this.hideInAccounting = this.functionalities.HideOptionsForAccountingProduct;
    this.showOtherCreditCardToggles = this.functionalities.ShowOtherTogglesInCreditCards ? this.functionalities.ShowOtherTogglesInCreditCards : false;
    this.allowTipConfig = this.functionalities.ShowTipConfigurationInCreditCards ? this.functionalities.ShowTipConfigurationInCreditCards : false;

    this.initializeInputs();
    this.setValidators();
    await this.getCreditCardConfiguration().finally(()=>{
      this.utilities.ToggleLoader(false);
    });

    this.creditcardInfo.controls['rGuestPayGatewayID'].valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(value => {
      this.setDisplayCardInputMethod(value);
    });
    this.isUpdateEnabled = false;

    this.creditcardInfo.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      if (this.creditcardInfo.controls.setCCOverAuthAdjustThreshold && this.localization.currencyToSQLFormat(this.creditcardInfo.controls.setCCOverAuthAdjustThreshold.value) > 100) {
        this.isUpdateEnabled = false;
      }
      else {
        this.isUpdateEnabled = (this.creditcardInfo.valid && this.creditcardInfo.dirty);
      }
    });    
    await this.getSurchargeConfig();
    if (this.allowTipConfig) {
      this.tipConfigForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
        this.isUpdateEnabled = (this.tipConfigForm.valid && this.tipConfigForm.dirty)
      });
      await this.getTipConfig();
    }
  }

  initializeInputs() {
    this.noOfSVCReceiptsInput = {
      className: 'ag_w--100',
      form: this.creditcardInfo,
      formControlName: 'noOfSvcReceipts',
      placeHolderId: 'lbl_noOfSVCReceipts',
      placeHolder: this.captions.lbl_noOfSVCReceipts,
      maxValue: 999,
      automationId: 'Txt_creditCard_noOfSvcReceipt'
    };
    this.batchWaitTimeInput = {
      className: 'ag_w--100',
      form: this.creditcardInfo,
      formControlName: 'batchWaitTimeSec',
      placeHolderId: 'lbl_batchWaitTimeSec',
      placeHolder: this.captions.lbl_batchWaitTimeSec,
      maxValue: 999,
      automationId: 'Txt_creditCard_batchWaitTime'
    };
    this.pbfeNetbDelayInput = {
      className: 'ag_w--100',
      form: this.creditcardInfo,
      formControlName: 'pbfeNetpfDelay',
      placeHolderId: 'lbl_pbfeNetbDelay',
      placeHolder: this.captions.lbl_pbfeNetbDelay,
      maxValue: 999,
      automationId: 'Txt_creditCard_pbefeNetbDelay'
    };
    this.ccOverAuthThresholdInput = {
      className: 'ag_w--100',
      form: this.creditcardInfo,
      maxValue: 100,
      maxlength: 3,
      formControlName: 'setCCOverAuthAdjustThreshold',
      placeHolderId: 'lbl_batchWaitTimeSec',
      placeHolder: this.captions.lbl_setCCAuthAdjustThreshold,
      maximumValueErrorMsg: this.captions.err_max,
      automationId: 'Txt_creditCard_ccOverAuthThreshold'
    };
    this.purgeStayCCDaysInput = {
      className: 'ag_w--100',
      form: this.creditcardInfo,
      formControlName: 'purgeStayCCDaysAfterCO',
      placeHolderId: 'lbl_purgeStayCCAfterCO',
      placeHolder: this.captions.lbl_purgeStayCCAfterCO,
      maxValue: 999,
      automationId: 'Txt_creditCard_purgeStayCCDays'
    };
    this.purgeArchivedCCDaysInput = {
      className: 'ag_w--100',
      form: this.creditcardInfo,
      formControlName: 'purgeArchivedCCDaysAfterCO',
      placeHolderId: 'lbl_purgeArchivedCCAfterCO',
      placeHolder: this.captions.lbl_purgeArchivedCCAfterCO,
      maxValue: 999,
      automationId: 'Txt_creditCard_purgeArchivedCcDays'
    };
    this.purgeGuestCCDaysInput = {
      className: 'ag_w--100',
      form: this.creditcardInfo,
      formControlName: 'purgeGuestCCDaysAfterCO',
      placeHolderId: 'lbl_purgeGuestCCAfterCO',
      placeHolder: this.captions.lbl_purgeGuestCCAfterCO,
      maxValue: 999,
      automationId: 'Txt_creditCard_purgeGuestcCdAYS'
    };
    this.purgeBookingCCDaysInput = {
      className: 'ag_w--100',
      form: this.creditcardInfo,
      formControlName: 'purgeBookingCCDaysAfterCO',
      placeHolderId: 'lbl_purgeBookingCCAfterCO',
      placeHolder: this.captions.lbl_purgeBookingCCAfterCO,
      maxValue: 999,
      automationId: 'Txt_creditCard_purgeBookingcCdAYS'
    };
    this.purgePostingCCDaysInput = {
      className: 'ag_w--100',
      form: this.creditcardInfo,
      formControlName: 'purgePostingCCDaysAfterCO',
      placeHolderId: 'lbl_purgePostingCCAfterCO',
      placeHolder: this.captions.lbl_purgePostingCCAfterCO,
      maxValue: 999,
      automationId: 'Txt_creditCard_purgePostingCcDays'
    };
  }
  patchFormData() {
    if (this.setting) {
      if (this.setting.GatewayPairs && this.setting.GatewayPairs.length > 0) {
        this.removeAllGatewayPairs();
      }
      this.setting.GatewayPairs.forEach(x => {
        this.addGatewayPairs(x.gateway_index, x.gateway_key, x.gateway_value);
      });
      this.creditcardInfo.patchValue(this.setting);
    }
    this.creditcardInfo.markAsPristine();
  }

  validateBreakPoint(): void {
    this.isUserAuthorized = this.breakpoint.CheckForAccess([myGlobals.RetailBreakPoint.CreditCards]);
    this.isViewOnly = this.breakpoint.IsViewOnly(myGlobals.RetailBreakPoint.CreditCards);
  }

  async getCreditCardConfiguration() {
    const result = await this.creditCardConfigurationService.getCreditCardConfiguration({ type: GatewayConfigurationType.CreditCard });
    this.setting = await this.creditCardConfigurationService.getCreditCardConfigurationByScreen();
    this.config = result.find(t => t.type == GatewayConfigurationType.CreditCard);
    
    if(this.config?.gatewayValues){
      this.setting.GatewayPairs = this.creditCardConfigurationService.MapUIGatewayValues(this.config.gatewayValues);
    }
    if (this.config) {
      if (this.config.gatewayValues && this.config.gatewayValues.length > 0) {
        this.removeAllGatewayPairs();
      }

      this.config.gatewayValues.forEach(x => {
        this.addGatewayPairs(x.configurationId, x.configKey, x.configValue);
      });
      this.setDisplayCardInputMethod(this.config.payGatewayID);
      this.patchValue();
      
    }
  }
  patchValue() {
    this.creditcardInfo.patchValue(this.setting);
    this.creditcardInfo.patchValue({
      cardInputMethod: this.config.cardInputMethod,
      preferredCardEntryMode: this.displayCardInputMethod ? this.config.preferredCardEntryMode : CardInputMethod.None,
      enableActivateInterface: this.config.isInterfaceActive,
      rGuestPayAgentURL: this.config.payAgentURL || '',
      rGuestPayGatewayID: this.config.payGatewayID || '',
      enablePartialPayment: this.config.isPartialPayAllowed,
      enableActivateAVS: this.config.isAVSActive,
      restrictCreditToGuest: this.config.restrictCreditToGuest,
      restrictPartialRefund: this.config.restrictPartialRefund
    });
  }
  setValidators() {
    this.creditcardInfo.get('enableActivateInterface').valueChanges.subscribe(val => {
      if (val) {
        this.creditcardInfo.get('rGuestPayGatewayID').setValidators([Validators.required, EmptyValueValidator]);
        this.creditcardInfo.get('rGuestPayGatewayID').updateValueAndValidity();
      }
      else {
        this.clearValidators(this.creditcardInfo.get('rGuestPayGatewayID') as UntypedFormControl);
      }

      this.toggleKeyValueValidation(val);
    });

    this.creditcardInfo.statusChanges.subscribe(() => {
      if (this.creditcardInfo.valid && this.creditcardInfo.dirty) {
        this.isUpdateEnabled = true;
      }
      else {
        this.isUpdateEnabled = false;
      }
    });
  }

  clearValidators(control: UntypedFormControl) {
    control.setValidators(null);
    control.clearValidators();
    control.updateValueAndValidity();
  }

  enableKeyValuePairValidation() {
    this.toggleKeyValueValidation(false);

    if (this.creditcardInfo.get('enableActivateInterface').value) {
      this.toggleKeyValueValidation(true);
    }

    this.creditcardInfo.get('GatewayPairs').markAsDirty();
    const gatewayValues: UntypedFormArray = this.creditcardInfo.get('GatewayPairs') as UntypedFormArray;
    gatewayValues.markAsDirty();
  }

  toggleKeyValueValidation(status: boolean) {
    if (status) {
      this.creditcardInfo.get('GatewayPairs')['controls'].map((x: UntypedFormGroup, index: number) => {
        x['controls']['gateway_key'].setValidators([Validators.required, EmptyValueValidator]);
        x['controls']['gateway_value'].setValidators([Validators.required, EmptyValueValidator]);

        if (index == 0) {
          x['controls']['gateway_key'].markAsTouched();
          x['controls']['gateway_value'].markAsTouched();
        }

        x['controls']['gateway_key'].updateValueAndValidity();
        x['controls']['gateway_value'].updateValueAndValidity();
      });
    }
    else {
      this.creditcardInfo.get('GatewayPairs')['controls'].map((x: UntypedFormGroup, index: number) => {
        this.clearValidators(x['controls']['gateway_key'] as UntypedFormControl);
        this.clearValidators(x['controls']['gateway_value'] as UntypedFormControl);
      });
    }
  }

  getErrorMessage(control: string, index: number) {
    if (control == "rGuestPayGatewayID") {
      return this.creditcardInfo.get('enableActivateInterface').value ? this.captions.MissingrGuestPayGateway : '';
    }
    else if (control == "GatewayPairs") {
      if (index == 0 && this.creditcardInfo.get('GatewayPairs')['controls'].length == 1) {
        return this.creditcardInfo.get('enableActivateInterface').value ? this.captions.MissingKeyPairValue : '';
      }
    }
  }

  public isControlValid(controlname: string) {
    const invalid = [];
    const controls = this.creditcardInfo.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid.indexOf(controlname) == -1;
  }

  createGatewayPairItem(_index: number, _key: any, _value: any): UntypedFormGroup {
    return this.fb.group({
      gateway_index: _index,
      gateway_key: _key,
      gateway_value: _value
    });
  }
  addGatewayPairs(_index: number, _key: any, _value: any) {

    if (!this.isControlValid('GatewayPairs')) {
      return;
    }

    this.GatewayPairs = this.creditcardInfo.get('GatewayPairs') as UntypedFormArray;
    this.GatewayPairs.push(this.createGatewayPairItem(_index, _key, _value));

    this.enableKeyValuePairValidation();
  }
  removeGatewayPairs(_index: any) {

    this.GatewayPairs = this.creditcardInfo.get('GatewayPairs') as UntypedFormArray || [];
    this.GatewayPairs.removeAt(_index);

    this.enableKeyValuePairValidation();
  }

  removeAllGatewayPairs() {
    this.GatewayPairs = this.creditcardInfo.get('GatewayPairs') as UntypedFormArray || [];
    while (this.GatewayPairs.length !== 0) {
      this.GatewayPairs.removeAt(0);
    }
  }

  onActiveChange() {
    this.creditcardInfo.markAsTouched();
    this.creditcardInfo.markAsDirty();
  }
  onCardInputMethodsChange($event) {
    this.creditcardInfo.controls.preferredCardEntryMode.setValue(CardInputMethod.None);
  }
  async save() {
    this.utilities.ToggleLoader(true,this.captions.lbl_processing);
    this.isUpdateEnabled = false;
    if (this.showOtherCreditCardToggles) {
      const data: UI.CreditCardConfiguration = this.creditcardInfo.getRawValue();
      if (this.setting) {
        data.defaultConfigValue = this.setting.defaultConfigValue;
        data.id = this.setting.id || 0;
        data.gatewayId = this.setting.gatewayId || 0;
      }
      await this.creditCardConfigurationService.updateCreditCardConfiguration(data);
      this.utilities.ToggleLoader(false);
    }

    const creditCardGateWayConfig: GatewayConfiguration[] = [];
    this.GatewayPairs = this.creditcardInfo.get('GatewayPairs') as UntypedFormArray;

    const gatewayValues: GatewayValues[] = this.GatewayPairs.value.map(x => {
      return {
        configValue: x.gateway_value.trim(),
        configKey: x.gateway_key.trim(),
        configurationId: ((this.config && this.config.id) || 0)
      };
    });
    const cardInputSelection = this.getCardInputMethod();
    const creditCardConfig: GatewayConfiguration = {
      id: ((this.config && this.config.id) || 0),
      payAgentURL: this.creditcardInfo.get('rGuestPayAgentURL').value.trim(),
      payGatewayID: this.creditcardInfo.get('rGuestPayGatewayID').value.trim(),
      isInterfaceActive: this.creditcardInfo.get('enableActivateInterface').value,
      isPartialPayAllowed: this.creditcardInfo.get('enablePartialPayment').value,
      isAVSActive: this.creditcardInfo.get('enableActivateAVS').value,
      restrictCreditToGuest: this.creditcardInfo.get('restrictCreditToGuest').value,
      restrictPartialRefund: this.creditcardInfo.get('restrictPartialRefund').value,
      voidAuthAfterCO : this.creditcardInfo.get('voidAuthAfterCO').value,
      gatewayValues: gatewayValues,
      type: GatewayConfigurationType.CreditCard,
      cardInputMethod: this.displayCardInputMethod ? cardInputSelection : CardInputMethod.None,
      preferredCardEntryMode: cardInputSelection == CardInputMethod.All && this.displayCardInputMethod
        ? this.creditcardInfo.get('preferredCardEntryMode').value : CardInputMethod.None,        
    };

    creditCardGateWayConfig[0] = creditCardConfig;
    await this.creditCardConfigurationService.createOrUpdateCreditCardConfiguration(creditCardGateWayConfig);
    this.utilities.ToggleLoader(false);
    if (this.surchargeConfigService.IsSurchargesEnabled && this.validateSurchargeChanges()) {
      this.SurchargeConfiguration = [];
      this.SurchargeConfiguration = await this.surchargeConfigService.SaveSurchargeConfigurationsWithCCIssuerType(this.surchargeConfig, this.SurchargeConfiguration);
      this.utilities.ToggleLoader(false);
      this.FormSurchargeConfigGrid(this.surchargeConfigService.mapAPIDataToUIElements(this.SurchargeConfiguration));
      this.surchargeConfigUpdated = false;
    }
    if (this.tipConfigForm.valid && this.allowTipConfig && this.tipConfigForm.dirty) {
      this.utilities.ToggleLoader(true, this.captions.lbl_processing);
      const tipConfig = this.tipConfigForm.getRawValue();
      const apiModel: TipConfigAPI = {
        id: tipConfig.id,
        enableTip: tipConfig.enableTip,
        tipInPercentage: tipConfig.isPercentage,
        presetValues: JSON.stringify(tipConfig.tipPresetValues.map(x => this.localization.currencyToSQLFormat(x.presetValue)))
      };
      await this.tipConfigService.CreateOrUpdateTipConfiguration(apiModel);
      await this.getTipConfig();
      this.utilities.ToggleLoader(false);
      this.tipConfigForm.markAsPristine();
      this.tipConfigForm.markAsUntouched();
      this.tipConfigForm.updateValueAndValidity();
    }
    await this.getCreditCardConfiguration();
    this.utilities.ToggleLoader(false);
    this.creditcardInfo.markAsUntouched();
    this.creditcardInfo.updateValueAndValidity();
    this.isUpdateEnabled = false;
    this.updateSurchargeGridControls();
  }

  async cancel() {
    this.utilities.ToggleLoader(true,this.captions.lbl_processing);
    await this.getCreditCardConfiguration();
    await this.getSurchargeConfig();
    await this.getTipConfig();
    this.isUpdateEnabled = false;
    this.utilities.ToggleLoader(false);
  }

  keyPress(event: any) {
    if (event.target.value.length >= 200) {
      event.preventDefault();
    }
  }

  setDisplayCardInputMethod(gatewayId: string) {
    if (gatewayId && gatewayId.trim().toLowerCase() === 'adyen') {
      this.displayCardInputMethod = true;
    } else {
      this.displayCardInputMethod = false;
    }
  }

  getCardInputMethod(): CardInputMethod {
    return this.creditcardInfo.get('rGuestPayGatewayID') &&
      this.creditcardInfo.get('rGuestPayGatewayID').value &&
      this.creditcardInfo.get('rGuestPayGatewayID').value.trim().toLowerCase() === 'adyen' ?
      this.creditcardInfo.get('cardInputMethod').value : CardInputMethod.All;
  }

  validateSurchargeChanges() {
    return ((this.surchargeConfig.every(x => x.id == 0) && this.surchargeConfig.some(x => x.isActive))
      || this.surchargeConfigUpdated)
  }

  async getSurchargeConfig() {
    if (!this.surchargeConfigService.IsSurchargesEnabled) return;
    try {
      this.SurchargeConfiguration = [];
      this.SurchargeConfiguration = await this.surchargeConfigService.GetSurchargeConfigurationWithCreditCardIssuer(PaymentMethods.CreditCard);
      this.FormSurchargeConfigGrid(this.surchargeConfigService.mapAPIDataToUIElements(this.SurchargeConfiguration));      
      this.updateSurchargeGridControls();
    } catch (error) {
      console.log(error);
    }
  }

  FormSurchargeConfigGrid(config: SurchargeConfiguration[]) {
    try {
      let CC_IssuerTypes = [
        CreditCardIssuerType.MaestroCard,
        CreditCardIssuerType.VisaCard,
        CreditCardIssuerType.MasterCard,
        CreditCardIssuerType.AmericanExpressCard,
        CreditCardIssuerType.DiscoverCard
      ];      
      const issuerTypeMap = {
        1: 'maestro',
        2: 'visa',
        3: 'masterCard',
        4: 'americanExpress',
        5: 'discover',
    };
      let defaultCCType: {displayName: string, issuerType: number}[] = [];
      CC_IssuerTypes.map(x=> defaultCCType.push({ displayName: this.surchargeConfigCaption?.IssuerType[x], issuerType: x}));         
      const initDefaultCardTypeConfig = (issuerName: string, issuerTypeId: number) => {
        const displayName = issuerName;
        let issuerType = issuerTypeMap[issuerTypeId]
        return <SurchargeConfiguration>{
          id: 0,
          isActive: false,
          isPercentage: true,
          paymentMethodId: 0,
          creditCardIssuerType: {id: 0, issuerType: issuerType, displayName: displayName},
          paymentTypeId: PaymentMethods.CreditCard,
          value: 0,
          issuerName: displayName,
          isValid: true,
          isDefault: true,
          maxLength: 3
        }
      }      
      this.surchargeConfig = [];
      if(config?.length == 0){
        defaultCCType.forEach(x => this.surchargeConfig.push(initDefaultCardTypeConfig(x.displayName , x.issuerType))); 
        return;
      }
      this.surchargeConfig = this.surchargeConfig.concat(config);
      this.surchargeConfig.forEach(config => {
        this.surchargeConfigService.UpdateFieldProperties(config);
        if(defaultCCType.some(x=> x.displayName == config?.creditCardIssuerType?.displayName)){
          config.isDefault = true;
          config.issuerName = config?.creditCardIssuerType?.displayName;
        }
      });      
      
    } catch (error) {
      console.log(error);
    }
  }

  chooseType(isPercentage, config: SurchargeConfiguration) {
    if(config.isPercentage != isPercentage){
      this.surchargeConfig.find(x => x.uniqueId == config.uniqueId).value = 0;
    }
    config.isPercentage = isPercentage;
    this.EnableSave();
    this.surchargeConfigService.UpdateFieldProperties(config);
  }

  EnableSave() {
    this.selectAll = this.surchargeConfig.every(x => x.isActive);
    this.isUpdateEnabled = false;    
    if(this.hasDuplicateIssuerTypes) return;
    if(this.IsInvalidSurchargeConfig) return;
    this.isUpdateEnabled = this.surchargeConfigUpdated = !this.surchargeConfig.some(x => x.isActive && String(x.value) == '');
  }

  enableAllSurcharge(event) {
    this.EnableSave();
    this.selectAll = event?.checked;
    this.surchargeConfig.forEach(x => x.isActive = event?.checked);
  }

  changeStatus(event, config: SurchargeConfiguration) {
    this.EnableSave();
    if (!event?.checked) {
      this.selectAll = false;
    }
  }

  updateSCVal(event) {
    this.EnableSave();
  }
  get hasDuplicateIssuerTypes(): boolean{
    const mySet = new Set(this.surchargeConfig.filter(x=> x.isActive).map(x => x.isDefault ? x.creditCardIssuerType.issuerType.replace(/ /g, "").toLowerCase() :  x.issuerName.replace(/ /g, "").toLowerCase()));
    if(mySet.size !== this.surchargeConfig.filter(x => x.isActive).length || mySet.has('')){     
      return true;
    }else{      
      this.surchargeConfig.forEach(x => x.isValid = true);
      return false;
    }
  }  
  updateIssuerType(event, config: SurchargeConfiguration){    
    this.surchargeConfig.find(x => x.uniqueId == config.uniqueId).isValid = true;
    let issuerType = [];
    this.surchargeConfig.map(x=> issuerType.push(x.isDefault ? x.creditCardIssuerType.issuerType : x.issuerName));
    const isDuplicate = issuerType.find(x => x == config.issuerName);
    if(isDuplicate){
      this.surchargeConfig.find(x => x.uniqueId == config.uniqueId).isValid = false;
    } 
    this.EnableSave();
  }
  addArray(e=null) {
    const newvalue = <SurchargeConfiguration>{
      id: 0,
      isActive: false,
      isPercentage: true,
      paymentMethodId: 0,
      creditCardIssuerType: {id: 0, issuerType: '', displayName: ''},
      paymentTypeId: PaymentMethods.CreditCard,
      value: 0,
      issuerName: '',
      isValid: true
    };    
    this.surchargeConfig.push(newvalue);   
    this.surchargeConfigService.UpdateFieldProperties(newvalue);
    this.updateSurchargeGridControls();
    this.EnableSave();
  }

  removeArray(e) {
    this.surchargeConfig = this.surchargeConfig.filter(x=> x.uniqueId.toString() != e.uniqueId.toString());
    if(this.surchargeConfig.length == 0){
      this.FormSurchargeConfigGrid([] as SurchargeConfiguration[]);
    }
    this.updateSurchargeGridControls();
    this.EnableSave();
  };
  updateSurchargeGridControls(){
    this.surchargeConfig.forEach(x=>{x.lastIndex = false; x.uniqueId= Guid.create()});    
    this.surchargeConfig[this.surchargeConfig.length-1].lastIndex = true;
    this.selectAll = this.surchargeConfig.every(x => x.isActive);
  }
  getMaxSurchargeValue(isPercentage: boolean): number{
    return isPercentage ? this.maxPercentage : Number.MAX_SAFE_INTEGER;
  }

  async getTipConfig() {
    if (!this.allowTipConfig) return;
    try {
      this.tipConfig = null;
      this.utilities.ToggleLoader(true);
      this.tipConfig = await this.tipConfigService.GetTipConfiguration();
      if (!this.tipConfig) {
        this.tipConfig = {
          id: 0,
          enableTip: false,
          tipInPercentage: true,
          presetValues: ""
        };
      }
      this.tipConfigForm.patchValue({
        id: this.tipConfig.id,
        enableTip: this.tipConfig.enableTip,
        isPercentage: this.tipConfig.tipInPercentage
      });
      this.tipIsPercentage = this.tipConfig.tipInPercentage;
      this.SetDecimalMaxLength();
      let presetValueValues = this.tipConfigForm.get("tipPresetValues") as UntypedFormArray;
      presetValueValues.clear();
      const apiPresetVal = this.tipConfig.presetValues ? JSON.parse(this.tipConfig.presetValues) : [0];
      apiPresetVal.map(t => { presetValueValues.push(this.createPresetValues(t)); });
      this.utilities.ToggleLoader(false);
    } catch (error) {
      this.utilities.ToggleLoader(false);
      console.log(error);
    }
  }

  SetDecimalMaxLength() {
    if (this.tipIsPercentage) {
      this.presetValMaxLength = MAXLENGTH.PERCENTAGE;
      this.decimalMaxLength = this.propertyDecimalConfig;
    } else {
      this.presetValMaxLength = MAXLENGTH.AMOUNT;
      this.decimalMaxLength = this.propDigits;
    }
  }
  
  chooseTipType(value: boolean) {
    this.tipIsPercentage = value;
    this.tipConfigForm.controls.isPercentage.setValue(value);
    this.SetDecimalMaxLength();
    let presetValueValues = this.tipConfigForm.get("tipPresetValues") as UntypedFormArray;
    presetValueValues.clear();
    if (this.tipConfig.tipInPercentage == this.tipIsPercentage) {
      const apiPresetVal = JSON.parse(this.tipConfig.presetValues);
      apiPresetVal.map(t => { presetValueValues.push(this.createPresetValues(t)); });
    } else {
      presetValueValues.push(this.createPresetValues(0));
    }
    this.tipConfigForm.markAsDirty();
    this.tipConfigForm.updateValueAndValidity();
  }

  removeTipPresetValue(_index) {
    let presetValueValues = this.tipConfigForm.get("tipPresetValues") as UntypedFormArray;
    presetValueValues.removeAt(_index);
    this.tipConfigForm.markAsDirty();
    this.tipConfigForm.updateValueAndValidity();
  }

  addTipPresetValue() {
    let _ctrls: any;
    let presetValueValues = this.tipConfigForm.get("tipPresetValues") as UntypedFormArray;
    if (this.tipConfigForm.controls.invalid || this.tipConfigForm.controls.enableTip.value == false || presetValueValues.length >= 4) {
      return;
    }
    presetValueValues.push(this.createPresetValues());
    this.tipConfigForm.markAsDirty();
    this.tipConfigForm.updateValueAndValidity();
  }

  createPresetValues(defaultValue: number = 0) {
    return this.fb.group({
      presetValue: [defaultValue, [EmptyValueValidator, Validators.min(1), Validators.required]]
    });
  }

}
