import { rGuestBaseService } from "./service/base.service";
import { EventEmitter } from "@angular/core";


export const registerEvent: EventEmitter<rGuestBaseService> = new EventEmitter();
export const unregisterEvent: EventEmitter<rGuestBaseService> = new EventEmitter();

export const DashBoardRegisterEvent: EventEmitter<any> = new EventEmitter();
export const THERAPIST = 'THERAPIST';
export const USER = 'USER';
export const SERVICECHARGE = 'SERVICECHARGE';
export const GRATUITY = 'GRATUITY';
export const HISTORY_DETAILS_DAYS_RANGE = 30;


export const DefaultGUID = "00000000-0000-0000-0000-000000000000";
export const DaysWithNumbers = [
  { id: 1, name: "Mon" },
  { id: 2, name: "Tue" },
  { id: 3, name: "Wed" },
  { id: 4, name: "Thu" },
  { id: 5, name: "Fri" },
  { id: 6, name: "Sat" },
  { id: 7, name: "Sun" },
]

export const enum PromptLevel {
  One = 1,
  Two,
  Three
}

export const enum PromptType {
  Delete = 1,
  UnsavedChanges,
  Prompt
}

export const taxValue = 10;

export const GridTimeInterval = 15;

export const version: string = "1.0.1";

export const FILENAME: string = "spa"

export const url: string = "api/data";

// CompressionLimit is the saturation point to start the compression
// Size in KB
export const COMPRESSION_LIMIT = 500;

// ALLOWED_IMAGE_SIZE is the max file size allowed.
// Size in MB
export const ALLOWED_IMAGE_SIZE = 2;
export const IDTechCardSwipeTimeout = 45000;
export const LAST_VISITED_APPT_SLOT = 'LastVisitedAppointmentSlot';

export const NUMBER_VALUES: any = {
  "one": 1,
  "two": 2,
  "three": 3,
  "four": 4,
  "five": 5,
  "six": 6,
  "seven": 7,

}
export const PMSDATA = 'pmsdata';
export declare const setting: any;

export const enum ButtonType {
  YesNo = 1,
  YesNoCancel = 2,
  OkCancel = 3,
  Ok = 4,
  SaveCancel = 5,
  ContinueCancel = 6,
  AddCancel = 7,
  OKOverride = 12
}

export const enum ButtonOptions {
  Yes = 'yes',
  No = 'no',
  Cancel = 'cancel',
  Ok = 'ok',
  Save = 'save',
  Add = 'Add',
  Override = "Override"
}
export const DepositRetailItemType = 6;
export const CancellationFeeRetailItemType = 12;
export const NoShowFeeRetailItemType = 13;
export const CancellationNoShowFeeSwitch = 'CANCELLATION_NOSHOW_FEE';
export const DefaultExistingClientCategory = 'DEFAULT_EXISTING_CLIENT_CATEGORY';
export const AdvancedAppointmentSearch = 'ADVANCED_APPOINTMENT_SEARCH';

export const PackageRetailItemType = 4;

export enum GridOperationType {
  Create = 1,
  Edit,
  Delete,
  ListOrderDrag
}
export enum DisplayTherapist{
  TherapistName =1,
  TherapistPicture
}

export enum ClientType{
  Guest,
  Member=2,
  HotelReservation
}

export enum AppointmentSearchType{
  Client,
  AppointmentId,
  AppointmentLinkId,
  clientName,
  bookingName,
  bookingId,
  ConfirmationId,
  CartConfirmationId
}

export enum clientSearchType{
  firstName=0,
  lastName,
  name,
  phone,
  email,
  patronId,
  All
}

export enum HotelClientSearchType{
  guestName,
  confirmationNumber,
  roomNumber,
  linkCode,
  contactDetail
}

export const enum PMSSystem{
  LMS,
  Stay
}

export enum memberSearchType{
  firstName=0,
  lastName,
  MemberNumber,
  phone,
  email,
  All
}

export enum PaidOptions{
  paid,
  unpaid,
}

export enum GridType {
  group = 1,
  location,
  therapist,
  medical,
  addons,
  equipment,
  spaServices,
  commission,
  spaPackage,
  appointmentsearch,
  spaPackageService,
  deletepackage,
  packageClasses,
  colorLink,
  lunchsetup,
  breakType,
  priceType,
  cancelReasons,
  password,
  locationMaintenance,
  equipmentMaintenance,
  therapistUnavailability,
  customfield,
  multipleAppointments,
  outlet,
  retail,
  quickSale,
  quickSaleCategories,
  creditcardterminal,
  categorygroup,
  commissionTemplate,
  retailtransactions,
  waitlist,
  roleSetup,
  userSetup,
  userRoleConfiguration,
  packageYielding,
  taxes,
  retailCategories,
  subCategory,
  retailSuppliers,
  retailOutlet,
  supplierDetails,
  unitOfMeasure,
  discountTypes,
  userMachineConfiguration,
  recieptConfiguration,
  templates,
  distributionList,
  exception,
  combineGuestRecords,
  dedupeGuestProfiles,
  member,
  lostDeniedreason,
  lostDeniedBusiness,
  inventorysync,
  logtype,
  guestType,
  relationship,
  classGroup,
  classSetup,
  instructorSetup,
  classLocation,
  classEquipment,
  viptype,
  Lockers
}

export enum RetailTransactions {
  mailorder = 1,
  reprintticket,
  opentransactions,
  correctvoid,
  pettycash,
  returnwithticket,
  returnwithoutticket,
  modifypostedcommission,
  modifypostedcommissionticketdetails
}
export enum PhoneType {
  Home = 1,
  Office,
  Mobile
}

export enum ActionType {
  recurring = 1,
  edit,
  cancel,
  copy,
  move,
  swap,
  print,
  recap,
  overbook,
  serviceaddon,
  linkmultipack,
  booklunch,
  changeservice,
  createtherapistbreak,
  modifytherapistbreak,
  undocheckin,
  checkin,
  checkout,
  adddeposit,
  delete,
  checkin_checkout,
  undocheckout,
  reinstate,
  notify,
  inTakeForm,
  changeSetup,
  staffChange,
  TransactionLog,
  createLostDenied,
  ViewEForm,
  GenerateEform,
  block,
  unBlock,
  assignTherapist,
  authorize,
  retailItem,
  lockers,
  noshow,
  cancelFee,
  cancelAll
}
export const enum ReceiptType {
  sales = 1,
  reprint = 2,
  void = 3,
  returnExchange = 4
}

export const enum Product {
  SPA = 1,
  RETAIL = 2,
  GOLF = 3,
  COMMON = 4
}

export const enum Host {
  image = "image",
  spaManagement = "spaManagement",
  schedule = "schedule",
  retailManagement = "retailManagement",
  retailPOS = "retailPOS",
  authentication = "authentication",
  reporting = "report",
  commission = "commission",
  payment = "payment",
  documentation = "documentation",
  common = "common",
  CommonGateway = "CommonGateway",

}
export const enum Module {
  client = "Client",
  appointment = "Appointment",
  home = "home"
}
export const enum ImgRefType {
  client = "CLIENT",
  therapist = "THERAPIST",
  retailItem = "RETAILITEM",
  guest = "GUEST",
  property = 'PROPERTYINFO'
}

export enum GenderPreference {
  none,
  enforceGender,
  preferMale,
  preferFemale,
  noPreference
}
export const enum Gender {
  Male = "Male",
  Female = "Female",
  EnforceGender = "EnforceGender",
  NoPreference = "NoPreference"
}


export class Config {
  value: any = 1;
}

export enum UtilityMoveOptions {
  therapistAppointments = 1,
  therapistLocation = 2,
  appointmentLocation = 3
}

export const enum AppointmentStatus {
  Scheduled = "RESV",
  Canceled = "CANC",
  Break = "BREAK",
  CheckIn = "CKIN",
  CheckOut = "CKOUT",
  NoShow = "NOSHOW",
  Temp = "TEMP",
  Block = "BLOCK"
}

export const enum AppointmentFrom {
  SpaWizard = "SpaWizard"
}

export const enum ContactMode {
  Phone = 'Phone',
  Email = 'Email'
}

export const enum ContactType {
  Home = 'Home',
  Mobile = 'Mobile',
  Office = 'Office'
}

export const enum ContactTypeId {
  Cell = 1,
  Home = 2,
  Work = 3,
  Personal = 9,
  Office = 10
}

export const enum ReportBreakPoint {
  ClientItinerary = 1200,
  BatchPrintClientItinerary = 1205,
  AppointmentListingbyLocation = 1210,
  AppointmentListingbyStaffMember = 1215,
  AppointmentListingbyCustomFields = 1220,
  AppointmentListing = 1225,
  CancellationReport = 1230,
  NoShowReport = 1235,
  StaffMemberSummaryReport = 1240,
  CommissionReport = 1245,
  GratuityReport = 1250,
  ServiceChargeReport = 1255,
  TransactionLog = 8090,
  ReturnedItems = 8060,
  InventoryAudit = 8085,
  InventoryTransfer = 8080,
  InventoryDetail = 8070,
  InventorySummary = 8065,
  InventoryOnHand = 8736,
  MultiPackItems = 8055,
  CorrectVoid = 8015,
  SaleByDiscountType = 8050,
  InventoryWash = 8075,
  SaleByItem = 8035,
  SaleByCategory = 8040,
  SaleBySubCategory = 8045,
  RevenueSummary = 8010,
  Shift = 8000,
  Transaction = 8005,
  AppointmentListingbyColorCode = 1260,
  StaffSchedule = 1265,
  GuestAppointments = 1270,
  Utilization = 1280,
  ProjectedRevenueSummary = 1275,
  ClientListing = 1285,
  AppointmentBookedByUser = 1290,
  Accrual = 8020,
  OnlineAppointment = 1295,
  SalesMix = 8140,
  UserAccessReport = 1300,
  TransactionExtract = 8175,
  MemberServiceAvailed = 15300,
  AppointmentDeposit=15500,
  LostDeniedReasons = 15081,
  LostDeniedBusiness = 15082,
  LostDeniedBusinessReport = 15083,
  LockerUtilization = 1305,
  ClassClientListing = 1310,
  ClassClientCancellation = 1315,
  ClassClientNoShow = 1320,
  DayPass = 1325,

}


export const enum SPAManagementBreakPoint {
  AddNewClientProfile = 600,
  EditClientProfile = 605,
  AccessClientMedicalHistory = 610,
  ViewPrivateMedicalCondition = 615,
  AddSOAPNotes = 620,
  EditSOAPNotes = 625,
  EditClientPreferences = 630,
  EditCustomField1 = 635,
  EditCustomField2 = 640,
  EditCustomField3 = 645,
  EditCustomField4 = 650,
  EditCustomField5 = 655,
  AddPriceType = 660,
  AddEditLocker = 680,
  AddClientBlockInfo = 665,
  EditClientBlockInfo = 670,


  EquipmentMaintenance = 2400,
  LocationMaintenance = 2405,
  TherapistUnavailability = 2410,
  MoveMultipleApptTherapistAppointments = 2415,
  MoveMultipleApptTherapistLocation = 2420,
  MoveMultipleApptAppointmentLocation = 2425,
  DayEnd = 2430,
  CombineGuestRecords = 2435,
  DeDupeGuestRecords = 12120,
  RemoveTempHold = 2440,
  CommissionClassSetup = 800,
  StaffCommissionSetup = 805,
  AddTherapistBreak = 2200,
  EditTherapistBreak = 2205,
  DeleteTherapistBreak = 2210,
  StaffSchedule = 2215,
  EditStaffSchedule = 2220,
  DeleteStaffSchedule = 2225,
  ServiceGroup = 2000,
  ServiceLocation = 2005,
  TherapistSetup = 2010,
  MedicationCondition = 2015,
  ServiceAddOn = 2020,
  ServiceEquipment = 2025,
  SpaService = 2030,
  SpaPackage = 2035,
  CancelReason = 2040,
  PackageClasses = 2045,
  LunchSetup = 2050,
  ColorLinking = 2055,
  BreakType = 2060,
  PriceType = 2065,
  CustomFields = 2070,
  PackageYielding = 2075,
  ServiceYielding = 2080,
  SystemSettings = 2085,
  MoveMultipleAppointments = 241524202425,
  Member=15200,
  LostDeniedReasons = 15081,
  paidUnpaidProfile =190760,
  QUICKIDCONFIG = 16020,
  LogType=2095,
  AutoNightAudit = 16025,
  GuestType=2100,
  Relationship=2105,
  CopyClientProfile=675,
  ClassGroup = 2000175,
  ClassLocation = 2000155,
  InstructorSetup = 2000160,
  ClassEquipment = 2000165,
  ClassSetup = 2000170
}



export const enum SPAScheduleBreakPoint {
  BookAppointment = 100,
  BookUnassignedTherapist = 105,
  BookSpaPackage = 110,
  BookExpressSpaPackage = 115,
  BookLunch = 120,
  ServiceAddOn = 125,
  EditAppointment = 130,
  CancelAppointment = 135,
  CheckInAppointment = 140,
  UndoCheckIn = 145,
  CheckOutAppointment = 150,
  CheckIn_CheckOutAppointment = 155,
  CopyAppointment = 160,
  MoveAppointment = 165,
  BookRecurringAppointments = 170,
  ChangeService = 175,
  ChangeService_PackageAppointment = 180,
  ChangeAppointmentStatus = 185,
  SwapAppointment = 190,
  OverbookTherapist = 195,
  OverbookLocation = 200,
  OverbookLunch = 205,
  AddDeposit = 210,
  LinkMultipack = 215,
  UnlinkMultipack = 220,
  ConvertAppointmentfromWaitlisttoScheduled = 225,
  RemoveDoNotMoveFlag = 230,
  EditCustomField1 = 235,
  EditCustomField2 = 240,
  EditCustomField3 = 245,
  EditCustomField4 = 250,
  EditCustomField5 = 255,
  EditAppointmentComments = 260,
  EditAppointmentServicePrice = 265,
  EditSetupTime = 270,
  EditBreakdownTime = 275,
  EditAppointmentDuration = 280,
  RecapAppointment = 285,
  PersonalInfoVisibleOnRecap = 290,
  overrideClientBlock = 295,
  AddRetailItem = 300,
  AddEditLockers = 305,
  AppointmentSearch = 400,
  ParkingLot = 405,
  AddWaitList = 410,
  ViewWaitList = 415,
  AppointmentConfirmation = 420,
  TransactionLog = 430,
  SettingServiceGroup = 2000,
  SettingServiceLocation = 2005,
  SettingTherapistSetup = 2010,
  SettingMedicationCondition = 2015,
  SettingServiceAddOn = 2020,
  SettingServiceEquipment = 2025,
  SettingSpaService = 2030,
  SettingSpaPackage = 2035,
  SettingCancelReason = 2040,
  SettingPackageClasses = 2045,
  SettingLunchSetup = 2050,
  SettingColorLinking = 2055,
  SettingBreakType = 2060,
  SettingPriceType = 2065,
  SettingCustomFields = 2070,
  SettingPackageYielding = 2075,
  SettingServiceYielding = 2080,
  SettingSystemSettings = 2085,
  UserSetup = 2300,
  UserRoleSetUp = 2305,
  UserRoleConfiguration = 2310,
  SpaWizard = 425,
  OverrideGenderPreference = 2445,
  MoveCheckedOutAppointment = 15400,
  LostDeniedReason=15081,
  LostDeniedBusiness=15082,
  GenerateEform = 20785,
  JobScheduler= 2090,
  SFTP = 15135,
  LogType=2095,
  LOCATION_BLOCK =20790,
  GuestType=2100,
  staffReaderBoard = 20035,
  Relationship=2105,
  CGPSFailedProfile=101546,
  SettingClassGroup = 2000175,
  SettingClassLocation = 2000155,
  SettingInstructorSetup = 2000160,
  SettingClassEquipment = 2000165,
  SettingClassSetup = 2000170,
  CreateClass = 7200,
	EditClass = 7205,
	DeleteClass = 7210,
	CancelClass = 7215,
  MoveClass = 7220,
	CopyClass = 7225,
	CheckInClient = 7230,
	CheckOutClient = 7235,
	UndoClassCheckIn = 7240,
  UndoCheckOut = 7245,
	AddClassDeposit = 7250,
	CancelClient = 7255,
	DeleteClient = 7260,
	EmailNotification = 7265,
	Comments = 7270,
  AddViewClient = 7275,
  HaltBooking = 7280,
  SpaOccupancy = 20075,
  SearchClass = 7285,
  LockerClass = 7290
}

export const enum RetailBreakPoint {
  CreateTransaction = 3000,
  ApplyGratuity = 3005,
  ApplyServiceCharge = 3010,
  ApplyCommission = 3015,
  EditGratuity = 3020,
  EditServiceCharge = 3025,
  EditCommission = 3030,
  ApplyDiscount = 3035,
  OutletSetup = 7000,
  ItemSetup = 7005,
  QuickSaleKeys = 7010,
  UnitofMeasure = 7015,
  SplitCommissionTemplateSetup = 7020,
  ReOpenTransaction = 5000,
  VoidTransaction = 5005,
  CorrectTransaction = 5007,
  ReturnWithTicket = 5010,
  ReturnWithoutTicket = 5015,
  ModifyPostedCommission = 5020,
  ReprintTicket = 5025,
  DiscountType = 7060,
  DiscountConfiguration = 7061,
  PaymentMethods = 7070,
  CategoryLinking = 7041,
  CategoryGroup = 7030,
  RetailCategories = 7035,
  RetailSubCategories = 7040,
  creditcardterminal = 7025,
  Taxconfiguration = 7050,
  Inventory = 8700,
  ItemTransfer = 8725,
  ReceiptConfiguration = 7080,
  TaxExempt = 3040,
  SettleToCreditCard = 3050,
  SettleToRoomCharge = 3055,
  SettleToCash = 3045,
  SettleToOthers = 3065,
  UserSessionConfiguration = 7085,
  StoreTerminal = 7090,
  inventorySync = 15170,
  AuthorizePayment = 3200,
  LockerType = 101547
}

export const enum InventoryBreakPoint {
  UpdateQTYonHand = 8700,
  FreezeInventory = 8705,
  ReleaseInventory = 8710,
  FullInventoryUpdate = 8715,
  PartialInventoryUpdate = 8720,
  ManualInventory = 8721,
  InventoryManagement = 8722,
  ItemTransfer = 8725,
  PrintBarcodes = 8730,
}

export const enum TherapistScheduleView {
   ClientInformation = 2000005,
   OtherDetails = 2000010,
	 TherapistEarnings = 2000015,
	 AppointmentPrice = 2000020,
	 ClientDetails = 2000025,
	 CheckOutComments = 2000030,
	ViewEarnings = 2000035,
  CurrentDayView = 2000040,
  ClientComments = 2000055,
  AppointmentComments = 2000060,
  ServiceAddOn = 2000065,
  SoapNotes = 2000070,
  TherapistView = 2000100,
  TherapistEForm = 2000075,
  AddRetailItem = 2000130,
  ApplyGratuiy = 2000135,
  ApplyServiceCharge = 2000140,
  ApplyDiscount = 2000145,
  ApplyCommission = 2000150
}

export const enum ErrorConstants {
  TherpistAlreadyBlocked = 923,
  TherapistNotScheduled = 925,
  LocationNotAvailable = 931,
  LocationAlreadyBlocked = 933,
  LocationUnderMaintenance = 934,
  ClientCanBeOverBooked = 944,
  ClientNotAvailable = 942,
  ClientCannotBeOverBooked = 943,
  TherapistNotCertifiedForAddOn = 928,
  TempNotFound = 1001,
  TherapistIsOnCall = 100013,
  LocationCannotBeBlocked = 500204
}

export const enum OperationType {
  None = 0,
  Create = 1,
  Edit = 2,
  Delete = 3
}

export enum ClassClientStatus {
  All = 0,
  Scheduled = 1,
  CheckedIn,
  CheckedOut,
  NoShow,
  Cancelled
}

export enum ClassStatus{
  Temp = -1,
  All = 0,
  Scheduled = 1,
  InProgress,
  Completed,
  Cancelled,
  Break,
  Halted,
  Open
}

export enum ClassClientAction {
  CheckIn = 1,
  CheckOut = 2,
  UndoCheckIn = 3,
  UndoCheckOut = 4,
  CheckInAndOut = 5,
  Delete = 6,
  Cancel = 7,
  Notify = 8
}

export type Partial<T> = {
  [P in keyof T]?: T[P];
}

export const allowedUrl: Array<any> = [false, 'report']
export const zipcodeformat: any = {
  'usFormat': '9999 9999 9999 9999',
  'uKFormat': '9999-9999-9999'
}

export enum noRecordsType {
  noRecords,
  noRecordsAfterSearch,
  noAppointmentsBooked
}

export enum ServiceChargeGratuityValueType {
  PreDefinedPercent = 0,
  CustomPercent,
  CustomAmount
}

export enum ServiceChargeGratuityPercentId {
  TenPercent = 1,
  FifteenPercent = 2,
  TwentyPercent = 3,
  TwentyFivePercent = 4
}

export enum PercentageAmount {
  Percentage = 1,
  Amount = 2,
  CustomPercentage = 3
}

export const IDTechCardSwipeTimeOut: number = 30 //In Seconds

export enum SelectType {
  single = 1,
  multiple = 2
}

export const enum Maxlength {
  FLATAMOUNT = 12,
  PERCENTAGE = 3
}

export const enum PatronInfoSearchResultType {
  EDITEXISTINGPATRON = 0,
  PATRONNOTFOUND,
  PATRONFOUND,
  UPDATECMSDATAONEXISTING
}

export const enum ElasticApm {
  UseApmServer = 'UseApmServer',
  ApmServerUrl = 'ApmServerUrl',
  DistributedTracingOrigins = 'DistributedTracingOrigins',
  ServiceName = 'SPA-UI'
}




export enum AlertType {
  Success = 1,
  Warning = 2,
  Error = 3,
  WellDone = 4,
  Info = 5,
  AccessDenied = 6,
  Done = 7
}

export enum AlertAction {
  CONTINUE = 'CONTINUE',
  CANCEL = 'CANCEL',
  YES = 'YES',
  NO = 'NO',
  RESV = 'RESEV',
  ALL = 'ALL',
  OK = 'OK',
  OVERRIDE = 'OVERRIDE'
}

export enum PhoneTypes {
  mobile = 1,
  home = 2,
  office = 3
}

export enum MailTypes {
  personal = 4,
  office = 5,
}

export enum ContactReferenceType {
  MANAGER = 1,
  CONTACT = 2,
  ACCOUNT = 3,
  INQUIRY = 4
}
export enum ContactInformationReferenceType {
  CONTACT = 1,
  ACCOUNT
}
export enum ContactAddressReferenceType {
  CONTACT = 1,
  ACCOUNT
}

export enum DisplayLine {
  all = 0,
  holiday = 1,
  hotDates = 2
}

export enum ManagerType {
  Sales = 1,
  ConferenceService,
  Catering
}
export enum InquiryDeniedReportType {
  Inquiry = 1,
  Denied = 2,
  Both = 3,
  BookingCreated = 4
}

export enum SalesCallFilterType {
  Account = 1,
  Manager = 2,
  CallType = 3,
  NextStep = 4,
  CallDate = 5
}

export enum AssociateType {
  Building = 1,
  SalesManager,
  ConferenceManager,
  CateringManager

}

export enum DialogViewOnly {
  print = 'print',
  cancel = 'cancel'
}

export interface MultiImageUpload {
  image: string;
  imageSize: number;
  thumbnail: string;
  thumbnailSize: number;
  filename: string;
}

export enum ActivityType {
  GOLF = "rG-Golf",
  PMSSTAY = "V1-Stay",
  SPA = "rG-Spa",
  SEAT = "rG-Seat",
  PMSSTAYLINKED = "V1-PMS" // this type indicates an activity has link to PMS stay in its date range.
}

export enum ActivityStatus {
  Unknown = -1,
  Reserved,
  CheckedIn,
  CheckedOut,
  NoShow,
  Cancelled
}

export enum InternalPMS{
  LMS ="LMS",
  Stay = "Stay"
}

export interface DefaultSettings {
  id: number;
  switch: string;
  switchType: string;
  value: string;
}

export enum ShortMonth{
  Jan=0,
  Feb,
  Mar,
  Apr,
  May,
  Jun,
  Jul,
  Aug,
  Sep,
  Oct,
  Nov,
  Dec
}

export enum PhoneNumberType {
  CELL = 'Cell',
  HOME = 'Home',
  WORK = 'Work',
}

export enum  DayPassStatus{
  Open=1,
  Success=2
}
export enum LockerAppointmentConstant {
  AppointmentEdit = 'AppointmentEdit',
  editLocker = 'Edit Locker',
  addLocker = 'Add Locker',
  removeLocker = 'Remove Locker'
}